import axios from "axios";
import authHeader from "./auth-header";
import { config } from "../config";
import AuthService from "./auth.service";
class ProposalService {
  getProposal(id) {
    return axios
      .get(`${config["apiUrl"]}proposals/${id}`, { headers: authHeader() })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  getSurvey(id) {
    return axios
      .get(`${config["apiUrl"]}proposals/${id}/survey`, { headers: authHeader() })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}

export default new ProposalService();
