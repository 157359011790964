<template>
  <div
    class="p-grid p-col-12 p-lg-12"
    v-if="proposal"
    :style="{ position: 'relative' }"
  >
    <div class="p-col-12 card p-shadow-3 p-d-flex p-ai-center">
      <h3>Proposta | {{ proposal.subject }}</h3>
    </div>
    <div class="p-col-12 p-md-6">
      <div class="p-col-12 card p-shadow-3">
        <div class="p-d-flex p-ai-center p-mt-1">
          <div class="p-col-3"><b>Cliente</b></div>
          <div class="p-col-9">
            {{ proposal.client_id ? proposal.client.name : "" }}
          </div>
        </div>
        <div class="p-d-flex p-ai-center p-mt-1">
          <div class="p-col-3"><b>Contacto</b></div>
          <div class="p-col-9">
            {{ proposal.contact_id ? proposal.contact.name : "" }}
          </div>
        </div>
        <div class="p-d-flex p-ai-center p-mt-1">
          <div class="p-col-3"><b>Vendedor</b></div>
          <div class="p-col-9">
            {{ proposal.seller_id ? proposal.seller.name : "" }}
          </div>
        </div>
        <div class="p-d-flex p-ai-center p-mt-1">
          <div class="p-col-3"><b>Responsável Orçamento</b></div>
          <div class="p-col-9">
            {{ proposal.assigned_to ? proposal.assigned_user.username : "" }}
          </div>
        </div>
        <div class="p-d-flex p-ai-center p-mt-1">
          <div class="p-col-3"><b>Categoria</b></div>
          <div class="p-col-9">
            {{ proposal.category ? proposal.category.name : "" }}
          </div>
        </div>
        <div class="p-d-flex p-ai-center p-mt-1">
          <div class="p-col-3"><b>Tipo</b></div>
          <div class="p-col-9">
            {{ proposal.type_id ? proposal.type.name : "" }}
          </div>
        </div>
        <div
          class="p-d-flex p-ai-center p-mt-1"
          v-if="proposal.type_id == 1 && survey"
        >
          <div class="p-col-3"><b>Estado Survey</b></div>
          <div class="p-col-9">
            {{ survey.status }}
          </div>
        </div>
        <div
          class="p-d-flex p-ai-center p-mt-1"
          v-if="
            proposal.type_id == 1 &&
            survey &&
            survey.status == 'Tentativa Marcação'
          "
        >
          <div class="p-col-3"><b>Tentativas de Marcação</b></div>
          <div class="p-col-9">
            {{ getAttempsurveySchedule(survey.attemptSchedule) }}
          </div>
        </div>
        <div
          class="p-d-flex p-ai-center p-mt-1"
          v-else-if="
            proposal.type_id == 1 && survey && survey.date != '1900-01-01'
          "
        >
          <div class="p-col-3"><b>Survey Marcação</b></div>
          <div class="p-col-9">{{ survey.date }} {{ survey.hour }}</div>
        </div>
        <div class="p-d-flex p-ai-center p-mt-1" v-if="proposal.type_id == 1">
          <div class="p-col-3"><b>Contactos Survey</b></div>
          <div class="p-col-9">
            {{ proposal.pat_contacts }}
          </div>
        </div>
        <div class="p-d-flex p-ai-center p-mt-1">
          <div class="p-col-3"><b>Orçamentado</b></div>
          <div class="p-col-9">
            {{ proposal.budgeted == 1 ? "Sim" : "Não" }}
          </div>
        </div>
        <div class="p-d-flex p-ai-center p-mt-1">
          <div class="p-col-3"><b>Criado</b></div>
          <div class="p-col-9">
            {{
              proposal.created_by == 0
                ? "Intranet"
                : proposal.created_user.username
            }}
            | {{ getDateTime(proposal.created_at) }}
          </div>
        </div>
        <div class="p-d-flex p-ai-center p-mt-1">
          <div class="p-col-3"><b>Atualizado</b></div>
          <div class="p-col-9">
            {{
              proposal.updated_user == 0
                ? "Intranet"
                : proposal.updated_user.username
            }}
            | {{ getDateTime(proposal.created_at) }}
          </div>
        </div>
      </div>

      <div
        class="p-col-12 card p-shadow-3"
        v-if="proposal.external_notes.length > 0"
      >
        <Accordion :multiple="true">
          <AccordionTab class="accordion-custom">
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-comments p-mr-2"></i>Notas
              </div>
            </template>
            <divider />
            <PrimeVueTimeline
              :value="proposal.external_notes"
              align="left"
              class="customized-timeline"
            >
              <template #marker>
                <span class="custom-marker p-shadow-4 p-mt-3">
                  <i :class="'pi pi-comment'"></i>
                </span>
              </template>
              <template #content="slotProps">
                <Card class="p-shadow-4 p-mt-2">
                  <template #title>
                    {{ slotProps.item.user_created.username }}
                  </template>
                  <template #subtitle>
                    {{ getDateTime(slotProps.item.created_at) }}
                  </template>
                  <template #content>
                    <div v-html="slotProps.item.note"></div>
                  </template>
                </Card>
              </template>
            </PrimeVueTimeline>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  </div>
</template>

<script>
import proposalService from "../services/proposal.service";
import { getOnlyDate, getOnlyTime } from "../helpers/helpers";
import PrimeVueTimeline from "primevue/timeline";
export default {
  name: "ProposalDetail",
  components: { PrimeVueTimeline },
  data() {
    return {
      proposal: null,
      survey: null,
    };
  },
  computed: {
    currentUser() {
      if (this.$store.state.auth.status.loggedIn) {
        return this.$store.state.auth.user;
      }
      return null;
    },
    proposalId() {
      return this.$route.params.id;
    },
  },
  async beforeCreate() {},
  async created() {
    this.loading = true;
    this.proposal = await proposalService.getProposal(this.proposalId);
    if (this.proposal.type_id == 1 && this.proposal.pat_id) {
      this.survey = await proposalService.getSurvey(this.proposalId);
    }
    this.loading = false;
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
  methods: {
    getDateTime(date) {
      let auxDate = new Date(date);
      return `${getOnlyDate(auxDate)} ${getOnlyTime(auxDate)}`;
    },
    getAttempsurveySchedule(attemptSchedule) {
      let attempt = attemptSchedule.split("_");
      if (attempt.length == 0) {
        return 0;
      }
      return attempt[0];
    },
  },
};
</script>
<style>
.custom-marker {
  display: -webkit-;
  display: -ms-flexbox;
  display: flex;
  width: 2rem;
  height: 2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  z-index: 1;
  background-color: #ff4910;
}
.p-timeline-event-opposite {
  display: none !important;
}
.p-accordion-header-link {
  background-color: #474a54 !important;
  color: #fff !important;
}
</style>
